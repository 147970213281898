<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-6 mx-auto">
                <h3>#訪問看護報告書</h3>
                <div class="card">
                    <div class="card-header bg-dark text-white">
                        対象者選択
                    </div>
                    <div class="card-body">
                        <form>
                            <v-select id="select"
                                      label="施設"
                                      :errors="hasErrors('facility_id')"
                                      @input="loadPersonData">
                                <option value="">施設を選択</option>
                                <option v-for="(facility,index) in facilities"
                                        :key="index"
                                        :value="facility.id">{{ facility.attributes.name }}
                                </option>
                            </v-select>
                            <div class="form-group row">
                                <label for="autocomplete" class="col-sm-3 col-form-label">対象者</label>
                                <div class="col-sm-7">
                                    <vue-simple-suggest v-model="name"
                                                        :filter-by-query="true"
                                                        :list="person"
                                                        id="autocomplete">
                                        <input :class="[{'is-invalid': isNameInvalid},'form-control']"
                                               type="text"
                                               placeholder="対象者名を入力">
                                        <div v-for="(error,index) in errors.name"
                                             :key="index"
                                             class="invalid-feedback">
                                            {{ error }}
                                        </div>
                                    </vue-simple-suggest>
                                </div>
                            </div>
                        </form>
                        <div class="mb-2 text-right">
                            <button type="button" class="btn btn-dark" @click="next">次へ</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VSelect from "@/components/Forms/VSelect";
import VueSimpleSuggest from "vue-simple-suggest";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "SelectPersonHomeNursingReport",
    components: {
        VSelect,
        VueSimpleSuggest
    },
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            facilities: {},
            person: [],
            selected: null,
            facility_id: '',
            name: '',
            isNameInvalid: false
        }
    },
    created: function () {
        this.loadFacilityData();
    },
    methods: {
        next: function () {
            this.clearErrors();
            this.loadSelectedPersonData();
        },
        loadFacilityData: function () {
            window.axios.get(this.routes.facilityAll)
                .then((response) => {
                    this.facilities = response.data.data;
                })
                .catch((error) => {

                })
        },
        loadPersonData: function (facility_id) {
            window.axios.get(this.routes.selectPerson, {
                params: {
                    facility_id: facility_id
                }
            })
                .then((response) => {
                    this.person = response.data;
                    this.facility_id = facility_id;
                })
                .catch((error) => {

                })
        },
        loadSelectedPersonData: function () {
            window.axios.get(this.routes.selectedPerson, {
                params: {
                    facility_id: this.facility_id,
                    name: this.name
                }
            })
                .then((response) => {
                    this.changeComponent(response.data.data);
                })
                .catch((error) => {
                    this.handleErrorStatusCode(error);
                    this.displayErrors();
                })
        },
        clearErrors: function () {
            this.errors = {};
            this.isNameInvalid = false;
        },
        displayErrors: function () {
            if (this.errors['name']) this.isNameInvalid = true;
        },
        changeComponent: function (person) {
            this.$router.push({name: 'HomeNursingReports', params: {id: person.id}})
        }
    }
}
</script>

<style scoped>

</style>
